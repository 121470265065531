import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';

const globalLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          logoText
          defaultTheme
          mainMenu {
            title
            path
          }
          showMenuItems
          menuMoreText
        }
      }
    }
  `);
  const {
    title,
    logoText,
    defaultTheme,
    mainMenu,
    showMenuItems,
    menuMoreText
  } = data.site.siteMetadata;

  return (
    <div className="container">
      <Header
        siteTitle={title}
        logoText={logoText}
        defaultTheme={defaultTheme}
        mainMenu={mainMenu}
        mainMenuItems={showMenuItems}
        menuMoreText={menuMoreText}
      />
      {children}
      <Footer />
    </div>
  );
};

globalLayout.propTypes = {
  children: PropTypes.any.isRequired
};

export default globalLayout;
