import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import cn from 'classnames';

import Menu from './menu';

import style from '../styles/header.module.css';

const Header = (props) => {
  const { logoText, mainMenu, mainMenuItems, menuMoreText, defaultTheme } = props;
  const defaultThemeState =
    (typeof window !== 'undefined' && window.localStorage.getItem('theme')) || null;
  const [userTheme, changeTheme] = useState(defaultThemeState);
  const [isMobileMenuVisible, toggleMobileMenu] = useState(false);
  const [isSubMenuVisible, toggleSubMenu] = useState(false);
  const onChangeTheme = () => {
    const opositeTheme = (userTheme || defaultTheme) === 'light' ? 'dark' : 'light';

    changeTheme(opositeTheme);

    if (typeof window !== 'undefined') {
      window.localStorage.setItem('theme', opositeTheme);
    }
  };
  const onToggleMobileMenu = () => toggleMobileMenu(!isMobileMenuVisible);
  const onToggleSubMenu = () => toggleSubMenu(!isSubMenuVisible);

  return (
    <>
      <Helmet>
        <body className={(userTheme || defaultTheme) === 'light' ? 'light-theme' : 'dark-theme'} />
      </Helmet>
      <header className={style.header}>
        <div className={style.inner}>
          <Link to="/" className="logoLink">
            <div className={style.logo}>
              <span className={style.mark}>&gt;</span>
              <span className={cn(style.line, style.typeAnimation)}>{logoText}</span>
              <span className={style.blinkTextCursor} />
            </div>
          </Link>
          <span className={style.right}>
            <Menu
              mainMenu={mainMenu}
              mainMenuItems={mainMenuItems}
              isMobileMenuVisible={isMobileMenuVisible}
              isSubMenuVisible={isSubMenuVisible}
              menuMoreText={menuMoreText}
              onToggleMobileMenu={onToggleMobileMenu}
              onToggleSubMenu={onToggleSubMenu}
              onChangeTheme={onChangeTheme}
            />
          </span>
        </div>
      </header>
    </>
  );
};

Header.defaultProps = {
  logoText: '',
  defaultTheme: '',
  mainMenu: [],
  mainMenuItems: undefined,
  menuMoreText: ''
};

Header.propTypes = {
  logoText: PropTypes.string,
  defaultTheme: PropTypes.string,
  mainMenu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string
    })
  ),
  mainMenuItems: PropTypes.number,
  menuMoreText: PropTypes.string
};

export default Header;
