import React from 'react';

const startedDate = 2020;
const currentYear = new Date().getFullYear();

const Footer = () => (
  <footer>
    <span className="footerCopyrights">
      ©{' '}
      <span className="date-range">
        {startedDate} - {currentYear}
      </span>{' '}
      Personal blog by{' '}
      <a href="https://github.com/Alex-Spike" target="_blank" rel="noreferrer">
        Alexey Nikiforov
      </a>
    </span>
  </footer>
);

export default Footer;
